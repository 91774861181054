import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';

// lazy load all the views
// auth


// landings

const Saas = React.lazy(() => import('../pages/landings/Saas/Saas'));
const support = React.lazy(() => import('../pages/other/Support'));
const Terms = React.lazy(() => import('../pages/other/Terms'));
const Privacy = React.lazy(() => import('../pages/other/Privacy'));
const Blog = React.lazy(() => import('../pages/other/Blog'));
const BlogPost = React.lazy(() => import('../pages/other/BlogPost'));
// const ContactUs = React.lazy(() => import('../pages/other/Contact'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                // {
                //     path: 'auth',
                //     children: [
                //         { path: 'login', element: <LoadComponent component={Login} /> },
                //         { path: 'signup', element: <LoadComponent component={SignUp} /> },
                //         { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                //         { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                //         { path: 'logout', element: <LoadComponent component={Logout} /> },
                //     ],
                // },
                // {
                //     path: 'docs',
                //     children: [
                //         { path: 'introduction', element: <LoadComponent component={Introduction} /> },
                //         { path: 'quick-start', element: <LoadComponent component={QuickStart} /> },
                //         { path: 'customization', element: <LoadComponent component={Customization} /> },
                //         { path: 'routing', element: <LoadComponent component={Routing} /> },
                //         { path: 'code-spliting', element: <LoadComponent component={CodeSpliting} /> },
                //         { path: 'change-log', element: <LoadComponent component={ChangeLog} /> },

                //         { path: 'colors', element: <LoadComponent component={Colors} /> },
                //         { path: 'typography', element: <LoadComponent component={Typography} /> },
                //         { path: 'bootstrap', element: <LoadComponent component={Bootstrap} /> },
                //         { path: 'custom', element: <LoadComponent component={Custom} /> },
                //         { path: 'plugins', element: <LoadComponent component={Plugins} /> },
                //         { path: 'navbars', element: <LoadComponent component={Navbars} /> },
                //         { path: 'heros', element: <LoadComponent component={Heros} /> },
                //     ],
                // },
                {
                    path: 'home',
                    element: <LoadComponent component={Saas} />,
                    
                },
                {
                    path: 'privacy-policy',
                    element: <LoadComponent component={Privacy} />,
                    
                },
                {
                    path: 'terms-of-use',
                    element: <LoadComponent component={Terms} />,
                    
                },
                {
                    path: 'support',
                    element: <LoadComponent component={support} />,
                    
                },
            //    {
            //         path: 'pages',
            //         children: [
            //             {
            //                 path: 'blog',
            //                 children: [
            //                     { path: '', element: <LoadComponent component={ContactUs} /> },
            //                     { path: 'post', element: <LoadComponent component={BlogPost} /> },
            //                 ],
            //             },
            //         ]
            //     },
                // {
                //     path: 'contactus',
                    
                //             children: [
                //                 { path: '', element: <LoadComponent component={ContactUs} /> },
                               
                            
                //     ]
                // },
            ],
        },
     
    ]);
};

export default AllRoutes;
